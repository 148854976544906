/*=============================================================================
    icons
=============================================================================*/
.icon {
    display: inline-block;
    vertical-align: middle;

    background-position: 49% 50%;
    background-size: 100%;
    background-repeat: no-repeat;

    transition: all 150ms;
}


.icon-chevron-left,
.icon-chevron-right,
.icon-chevron-left-white,
.icon-chevron-right-white {
    width: 13px;
    height:  24px;
}

.icon-chevron-left {
    background-image: url('../img/i-chevron-left.svg');

    .no-svg & {
        background-image: url('../img/i-chevron-left.png');
    }
}

.icon-chevron-right {
    background-image: url('../img/i-chevron-right.svg');

    .no-svg & {
        background-image: url('../img/i-chevron-right.png');
    }
}

.icon-chevron-left-white {
    background-image: url('../img/i-chevron-left-white.svg');

    .no-svg & {
        background-image: url('../img/i-chevron-left-white.png');
    }
}

.icon-chevron-right-white {
    background-image: url('../img/i-chevron-right-white.svg');

    .no-svg & {
        background-image: url('../img/i-chevron-right-white.png');
    }
}

.icon-chevton-up,
.icon-chevron-down {
    width: 24px;
    height: 13px;
}

.icon-chevron-down {
    background-image: url('../img/i-chevron-down.svg');

    .no-svg & {
        background-image: url('../img/i-chevron-down.png');
    }
}


.icon-close {
    width: 14px;
    height: 14px;
    background-image: url('../img/i-close-white.svg');

    .no-svg & {
        background-image: url('../img/i-close-white.png');
    }
}

.icon-close-red {
    width: 14px;
    height: 14px;
    background-image: url('../img/i-close-red.svg');

    .no-svg & {
        background-image: url('../img/i-close-red.png');
    }
}


.hamburger {
    display: block;
    width: 100%;
    color: $black;
}

.hamburger__bar {
    width: 100%;
    border-bottom-width: 1px;
    border-bottom-style: solid;

    & + & {
        margin-top: 8px;
    }
}

.menu-btn {
    position: absolute;
    top: 0;
    right: 20px;

    width: 40px;
    height: 40px;
    padding: 0 5px;
    background: rgba(255, 255, 255, .6);
    border: none;

    &.is-active,
    &:hover {
        background: $white;
    }
}

.list-reset {
    padding: 0;
    margin: 0;
    list-style: none;
}

.bookadlo-background {
    background-image: url('../img/bg-1.jpg');
    background-size: cover;
    background-position: 50% 50%;
    background-attachment: fixed;
}

.bg-transparent {
    background: rgba($black2, $opacity2);
}

/*=============================================================================
    collapse
=============================================================================*/
.collapse-sm {
    @include media-max($sm) {
        display: none;

        &.is-expanded {
            display: block;
        }
    }
}

/*=============================================================================
    form-components
=============================================================================*/
.input,
.select {
    outline-color: rgba(black, 0.5);
}

.input {
    height: 35px;
    padding: 0 10px;
    border: 1px solid transparent;

    line-height: 40px;
    letter-spacing: 2px;

    transition: all 150ms;

    @include placeholder {
        color: #999;
        opacity: 1;

        transition: all 250ms;
    }

    &:focus {
        @include placeholder {
            opacity: 0;
        }
    }
}

.input--ghost {
    height: auto;
    border: none;
    background: none;

    line-height: 1.4;

    @include placeholder {
        color: $black;
    }
}

.input--full-width {
    width: 100%;
}

.input--time {
    width: 70px;
    color: #FFF !important;
}

// select
//=============================================================================
.select {
    width: 100%;
    height: 40px;
    padding: 0 30px 0 10px;
    border: none;
    background: rgba($white, $opacity);

    line-height: 40px;

    transition: all 150ms;
}

// ui-slider
//=============================================================================
.ui-slider {
    height: 14px;
    border: none;
    border-radius: 0;
    background: rgba($white, $opacity);
}

.ui-slider .ui-slider-handle {
    top: -6px;

    width: 26px;
    height: 26px;
    border:none;
    border-radius: 50%;
    background: $black;
}

/*=============================================================================
    vertical alignment
=============================================================================*/
.va {
    display: table;
    width: 100%;
    height: 100%;
}

.va__inner {
    display: table-cell;
    vertical-align: middle;
}


/*=============================================================================
    module
=============================================================================*/
.module {
    margin: 30px 0;
}

.module-header {
    color: #FFF;
    background: rgba($black2, $opacity2);

    &.darker{
        background: rgba($black2, $opacity);
    }
}

.module-header {
    position: relative;
    padding: 2px 30px;
    margin: 0 0 10px;
}

.module-header__title {
    margin: 0;
    font-size: 16px;
    text-transform: uppercase;
}

.module-header__close {
    position: absolute;
    top: 0;
    right: 5px;

    height: 100%;
}

.module-main {
    padding: 10px;

    .grid-box{
        background: rgba($black2, $opacity);
    }
}

.module--with-grid .module-main {
    padding: 0;
}

.module-footer {
    margin: 10px 0 0;
}

.module-footer--centered {
    text-align: center;
}

.module-footer__btn {
    @extend .bg-transparent;

    width: 40px;
    height: 40px;
    padding: 0;
    border: none;
    margin: 20px 0 0;

    &:hover,
    &:focus {
        background: $white;
    }
}


.link-underline{
    font-weight: bold;
    text-decoration: underline;
    &:hover{
        text-decoration: none;
    }
}


/*=============================================================================
    popover
=============================================================================*/
.popover{
    display: none;
    position: fixed;
    width: 350px;
    padding: 20px;
    background: rgba(0,0,0, .8);
    color: #FFF;
    z-index: 100;
    top: 10%;
    left: 50%;
    margin-left: -175px;
    text-align: center;
    .close-popover{
        float: right;
    }
    p{
        clear: both;
    }
}

.fade{
    display: none;
    z-index: 99;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: .7;
    position: absolute;
    top: 0;
    left: 0;
}


/*=============================================================================
    modal
=============================================================================*/

// add class to body when modal is opened
.modal-open {
    overflow: hidden;
}

.modal-overlay {
    position: fixed;
    z-index: 30;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background: rgba(white, 0.8);

    overflow: hidden;
    overflow-y: auto;
}

.modal {
    @extend .container;

    position: relative;
    margin: 30px auto;
}

.modal-close {
    position: absolute;
    display: block;
    border: none;
    background: none;

    .icon {
        display: block;
    }

    &:hover,
    &:focus {
        .icon {
            @extend .icon-close-red;
        }
    }
}

/*=============================================================================
    thank you
=============================================================================*/
.ty {
    padding: 10px;
    color: #FFF;
}