/*
******************************************************************************
 * Header style
 ******************************************************************************/
 .l-header {
    height: 132px;
 }

 .l-header-fixed {
    background: rgba(0,0,0,.53);
    height: 42px;
    margin: 45px 0 45px 0;
    transition: background .2s;
    color: #FFF;
    position: fixed;
    z-index: 20;
    top: 0px;
    width: 100%;
    border-top: 0;
    border-bottom: 0;
}

.l-header-fixed:hover{
    background: rgba(0,0,0,.83);
}

.l-header .primary-nav, .l-header .languages {
    height: 40px;
    margin: 2px 0 0 45px;
    display: inline-block;
    vertical-align: top;
    line-height: 40px;
    font-size: 13pt;
    letter-spacing: 2px;
    font-family: DolceVita;
    font-weight: 400;
    position: absolute;
}

.primary-nav > ul > li,
.languages > ul > li {
    margin-right: 85px;
    display: block;
    float: left;
}

.primary-nav {
    > ul > li {
        margin-right: 30px;
    }
}

.primary-nav > ul > li > a,
.languages > ul > li > a {
    display: inline;

    color: $white;
    text-decoration: none;
    text-transform: uppercase;
}


.primary-nav {
    & > ul > li > a:hover,
    & > ul > li > a:focus,
    & > ul > li.active > a {
        // font-weight: 300;
    }
}

.l-header .languages {
    right:0px;
}

.logocont {
    position: relative;
    z-index: 10;
}

.logocont img {
    margin: -20px 30px 0 20px;
}


.l-header__menu {
    float: left;
    margin-left: 80px;
}

@include media-max($xl) {
    .languages > ul > li {
        margin-right: 20px;
    }
}

/*******************************************************************************
 * Footer
 ******************************************************************************/
.l-footer {
    width: 100%;
    height: 32px;
    color: #FFF;
    position: fixed;
    z-index: 20;
    bottom: 0;
    background: url(../img/bkg-65p.png);
    background: rgba(0,0,0,.53);

    a {
        font-weight: 300;
        color: inherit;
        text-decoration: none;

        &:hover,
        &:focus {
            // font-weight: normal;
        }
    }
}
.l-footer > div{
    font-size: 10pt;
    line-height: 37px;
}
.l-footer .social-networks{
    float: left;
}
.l-footer .address{
    text-align: center;
}
.l-footer p {
    margin: 0;
    font-size: 15px;
    text-align: center;
    letter-spacing: 7px;
}
.l-footer img {
    vertical-align: sub;
}


/*Retina graphics!*/
@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
       only screen and (min--moz-device-pixel-ratio: 1.5),
       only screen and (min-device-pixel-ratio: 1.5){

    #fancybox-loading, .fancybox-close, .fancybox-prev span, .fancybox-next span {
        background-image: url('/assets/img/fancybox/fancybox_sprite@2x.png');
        background-size: 44px 152px; /*The size of the normal image, half the size of the hi-res image*/
    }

    #fancybox-loading div {
        background-image: url('/assets/img/fancybox/fancybox_loading@2x.gif');
        background-size: 24px 24px; /*The size of the normal image, half the size of the hi-res image*/
    }
}





@media only screen and (min--moz-device-pixel-ratio: 2),
only screen and (-o-min-device-pixel-ratio: 2/1),
only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (min-device-pixel-ratio: 2) {
    .logo {
        background: url(/path/to/my/highreslogo.png) no-repeat;
        background-size: 100px 100px;
        /* rest of your styles... */
    }
}

@media all and (max-width: $md) {

    .l-header .container {
        padding: 0;
    }

    a.logocont img {
        height: 60px;
        width: 60px;
        margin: -10px 0 0 10px;
    }

    .primary-nav li {
        margin-left: 0;
        margin-right: 3% !important;
    }


    .primary-nav {
        position: absolute;
        left: 0px;
        top: 0px;
        padding-left: 100px !important;
        width: 100% !important;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        margin: 2px 0 0 -10px !important;
    }
    .l-header .languages li {
        font-size: 11px;
        background-color: #bbb;
        text-align: center;
        width:40px;
        margin:-2px 0;
        -webkit-transform: rotate(-90deg);
        -moz-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        -o-transform: rotate(-90deg);
        filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
    }

    .l-footer .container {
        padding: 0;
    }

    .l-footer img {
        display: none;
    }
}

@media all and (max-width: 700px) {
    .l-footer a {
        font-weight: normal;
    }

    .l-footer p {
        font-size: 10pt;
        font-family: DolceVita;
        letter-spacing: 4px;
    }
    .l-footer {
        height: 30px;
    }
    .l-footer > div {
        line-height:35px;
    }
}
@media all and (max-width: 550px) {
    .l-footer p {
        font-size: 13px;
        letter-spacing: 3px;
    }
    // .primary-nav li {
    //     margin-right: 5% !important;
    // }
}

@media all and (max-width: 450px) {

    .l-footer p {
        font-size: 13px;
        letter-spacing: 2px;
    }

    // a.logocont img {
    //     height: 60px;
    //     width: 60px;
    //     margin: -10px 0 0 10px;
    // }

    // .primary-nav {
    //     margin: 2px 0 0 -10px !important;
    // }
    // .primary-nav li {
    //     margin-left: 0;
    //     margin-right: 10% !important;
    //     font-size:13px;
    // }
}

// @media all and (max-width: 420px) {
//     .primary-nav li {
//         margin-left: 0;
//         margin-right: 5% !important;
//         font-size:13px;
//     }
// }

// @media all and (max-width: 350px) {

//     .primary-nav li {
//         margin-left: 0;
//         margin-right: 12% !important;
//         font-size:13px;
//     }

//     .primary-nav li:last-child {
//         display:none;
//     }

//     .l-footer p {
//         font-size: 12px;
//         letter-spacing: 1px;
//     }

//     .primary-nav {
//         margin: 2px 0 0 -20px !important;
//     }
// }


@include media-max($sm) {
    .l-header__menu {
        position: absolute;
        top: 41px;
        left: 0;
        right: 0;

        padding: 10px 0;
        margin: 0;
        background:rgba(0, 0, 0, 0.7);
    }

    .l-header .primary-nav, .l-header .languages {
        position: static;
        display: block;
        height: auto;
        padding: 0 !important;
        margin: 0 !important;
    }

    .primary-nav,
    .languages {
        > ul > li {
            float: none;
            margin: 0 auto !important;
            text-align: center;
        }
    }

    .l-header .languages {
        margin: 10px auto 0 !important;
    }

    .l-header .languages li {
        // font-size: 11px;
        // background-color: #bbb;
        // text-align: center;
        // width:40px;
        // margin:-2px 0;
        // -webkit-transform: rotate(-90deg);
        // -moz-transform: rotate(-90deg);
        // -ms-transform: rotate(-90deg);
        // -o-transform: rotate(-90deg);
        // filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);

        width: 100%;
        margin: 0 auto;

        font-size: inherit;
        transform: none;

    }
}
