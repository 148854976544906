/*=============================================================================
    reservation
=============================================================================*/

.reservation-sh {
    padding-top: 10px;
    padding-bottom: 10px;
}

.reservation-heading {
    margin: 0 0 10px;
}

$cubic: cubic-bezier(.64,.09,.08,1);

[class^="tooltip"] {
    position: relative;
    &:after {
      opacity: 0;
      visibility: hidden;
      position: absolute;
      content: attr(data-tooltip);
      padding: 6px 10px;
      top: -15.4em;
      width: 400px;
      left: 50%;
      transform: translateX(-50%) translateY(-2px);
      background: rgba(255,255,255,.9);
      color: #000;
      white-space: pre-wrap;
      z-index: 2;
      border-radius: 2px;
      transition: opacity 0.2s $cubic, transform 0.2s $cubic;
    }
    &:hover {
      &:after {
        display: block;
        opacity: 1;
        visibility: visible;
        transform: translateX(-50%) translateY(0);
      }
    }
  }

.reservation-heading--half {
    margin-right: 50%;
    padding-right: 5px;

    @include media-max($xs) {
        margin-right: 0;
        padding-right: 0;
    }
}

.reservation-info {
    text-align: center;
}

.reservation-group + .reservation-group {
    margin-top: 0.4em;
}

.reservation-group{
    color: #FFF;

    
    select, input{
        color:#000;
    }

}
.reservation-heading{
    color:#FFF;
}


.reservation-label {
    color:#FFF;
    display: inline-block;
    margin: 0 0 5px;
}


.reservation-value {

}

.reservation-value--block {
    display: block;
}


.reservation-address {
    font-style: normal;
}


.reservation-time {
    position: relative;
    padding: 0 22px;
}

.reservation-time-control {
    position: absolute;

    width: 22px;
    height: 22px;
    padding: 0;
    border: none;
    color: #000;
    background: rgba($white, $opacity);

    line-height: 22px;
    transition: background 150ms;

    .icon {
        font-size: 22px;
    }

    &:hover,
    &:focus {
        background: $white;
        color: $black;
    }
}

.reservation-time-control--minus {
    left: 0;
}

.reservation-time-control--plus {
    right: 0;
}

.reservation-input {
    text-align: center;
}

.reservation-submit {
    display: block;
    width: 100%;
    padding: 20px;
    color:#FFF;
    border: none;

    background: rgba($black2, $opacity);

    &:hover,
    &:focus {
        background: $black2;
        text-decoration: underline;
    }
}

@include media-max($xs) {
    .reservation-info + .reservation-info {
        margin-top: 1.4em;
    }

    .reservation-submit {
        margin-top: 10px;
    }
}