/*  select-control
=============================================================================*/

.sh-control {
    position: absolute;
    z-index: 0;
    top: 0;

    width: 45px;
    height: 100%;
    // padding: 0 10px;
    // border: none;
    border: 1px solid transparent;
    background: none;

    transition: all 150ms;

    // -webkit-touch-callout: none;
    // -webkit-user-select: none;
    // -khtml-user-select: none;
    // -moz-user-select: none;
    // -ms-user-select: none;
    // user-select: none;

    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    -webkit-tap-highlight-color: transparent;

    outline: none;

    .no-touch &:hover,
    &:active {
        // background: rgba($white, $opacity);
        // background: rgba(black, 0.7);
        border-color: rgba(white, 0.7);

        .icon:before {
            // background: $white;
        }
    }

    .icon {
        position: relative;

        &:before {
            content: ' ';
            position: absolute;
            top: 0;

            width: 1px;
            height: 100%;

            background: $black;

            transition: all 150ms;
        }
    }
}

.sh-control--previous {
    left: 0;

    padding-left: 9px;
    text-align: left;

    &:hover {
        .icon {
            // @extend .icon-chevron-left-white;
        }
    }

    .icon:before {
        background: #FFF;
        right: -10px;
    }
}

.sh-control--next {
    right: 0;

    padding-right: 9px;
    text-align: right;

    &:hover {
        .icon {
            // @extend .icon-chevron-right-white;
        }
    }

    .icon:before {
        background: #FFF;
        left: -10px;
    }
}

// /*  select-list
// =============================================================================*/

// .select-list-wrapper {
//     overflow: hidden;
// }

// .select-list {

// }

// .select-item {
//     text-align: center;
//     vertical-align: top;
// }

// .select-list--horizontal {
//     min-height: 100px;
//     margin: 0 -5px;
//     font-size: 0;
//     overflow: hidden;
//     white-space: nowrap;

//     > .select-item {
//         display: inline-block;
//         width: 25%;
//         padding: 0 5px;
//         font-size: $fs-default;
//         white-space: normal;

//         @include media-max($sm) {
//             width: 50%;
//         }
//     }

//     &.select-list--one-col {
//         > .select-item {
//             width: 100%;
//         }
//     }

//     &.select-list--sm-one-col {
//         @include media-max($sm) {
//             > .select-item {
//                 width: 100%;
//             }
//         }
//     }
// }


.select-option {
    position: relative;
    z-index: 0;

    display: block;
    // padding: 5px;
    border: 1px solid transparent;

    color: inherit;
    text-decoration: none;

    transition: all 150ms;

    outline: none;


    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    -webkit-tap-highlight-color: transparent;

    &:hover,
    &:focus {
        border-color: rgba(white, 0.7);
    }

    &.is-disabled:hover,
    &.is-disabled:focus {
        border-color: transparent;
    }
}

.select-option--sh {
    min-height: 100px;
    line-height: 98px;

    > .select-option__label {
        line-height: 1.4;
        color:#FFF;
        vertical-align: middle;
    }
}

.select-option--sv {
    min-height: 40px;
    line-height: 34px; //so that the height is 40px

    > .select-option__label {
        display: block;
    }
}

.select-option__input {
    display: none;
}

.select-option__label {
    display: inline-block;
    width: 100%;

    cursor: pointer;
    transition: all 150ms;


    &:after {
        content: ' ';
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;

        top: -1px;
        right: -1px;
        bottom: -1px;
        left: -1px;

        transition: background 150ms, border-color 150ms;
    }

    .select-option__input:checked + & {
        color: $black2;

        &:after {
            background: rgba(white, 0.8);
        }
    }

    .select-option.is-disabled &,
    .select-option__input:disabled + & {
        position: relative;
        // background: rgba($white, $opacity);
        color: #aaa;
        pointer-events: none;
        text-decoration: line-through;

        // &:before {
        //     content: ' ';
        //     position: absolute;
        //     top: 50%;
        //     left: 50%;

        //     display: block;
        //     width: 30px;
        //     height: 1px;
        //     margin-left: -17px;

        //     background: $gray;
        // }
    }
}

.select-option:hover,
.select-option:focus {

    .select-option__input:checked + .select-option__label {
        &:after {
            color: #000;
            background: rgba(white, 0.8);
        }
    }
}

.select-option.not-clickable:hover,
.select-option.not-clickable:focus {
    border-color: transparent;

}


.select-option.is-disabled .select-option__label {

    &:after {
        background: none !important;
    }
}


// select horizontal & select vetical
//=============================================================================


.sh-items,
.sv-items {
    font-size: 0;
    letter-spacing: 0;
    white-space: nowrap;
}

.sh-item,
.sv-item {
    color:#FFF;
    font-size: $fs-default;
    letter-spacing: 2px;
    white-space: normal;

    vertical-align: top;
}
// select horizontal
//=============================================================================
.sh {
    position: relative;
    padding: 0 50px;


    line-height: 1.4;
}

.sh-items-wrapper {
    overflow-x: hidden;
}

.sh-items--two-cols {
    > .sh-item {
        width: 50%;

        @include media-max($xs) {
            width: 100%;
        }
    }
}

.sh-items--one-col {
    > .sh-item {
        width: 100%;
        padding: 0;
    }
}

.sh-item {
    display: inline-block;
    width: 25%;
    padding: 0 5px;

    font-size: $fs-default;
    text-align: center;
    white-space: normal;

    @include media-max($lg) {
        width: (100 / 3 * 1%);
    }

    @include media-max($sm) {
        width: 50%
    }

    @include media-max($xs) {
        width: 100%;
    }
}

// select vertical
//=============================================================================

.sv {
    line-height: 1.4;
}
.sv-header {
    color: #FFF;
    padding: 5px 5px;
}

.sv-header__title {
    margin: 0;
}