$black: #333;
$white: #fff;
$black2: #000;

$gray: #777;

$opacity: 0.80;
$opacity2: 0.53;

$fs-default: 16px;

/*=============================================================================
    breakpoints
=============================================================================*/
$xs: 480px;
$sm: 720px;
$md: 850px;
$lg: 1000px;
$xl: 1200px;


/*=============================================================================
    mixins
=============================================================================*/

@mixin media-max($bp) {
    @media (max-width: $bp) {
        @content;
    }
}

@mixin media-min($bp) {
    @media (min-width: ($bp + 1px)) {
        @content;
    }
}

@mixin retina {
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        @content;
    }
}

@mixin placeholder {
    &::-webkit-input-placeholder {
        @content;
    }

    &:-moz-placeholder { /* Firefox 18- */
        @content;
    }

    &::-moz-placeholder {  /* Firefox 19+ */
        @content;
    }

    &:-ms-input-placeholder {
        @content;
    }
}