.container {
    width: 100%;
    max-width: 830px;
    padding: 0 15px;
    margin: 0 auto;

    @include media-max(850px) {
        max-width: none;
        padding: 0 5%;
    }
}

.grid {
    padding: 0 0 10px;
    margin: 0 -5px;
    font-size: 0;
    letter-spacing: 0;
}

.grid-gap-bottom {
    padding-bottom: 10px;

    &.darker{        
        .bg-transparent{
            background: rgba($black2, $opacity);
        }
    }
}

.grid-cell {
    display: inline-block;
    width: 100%;
    padding: 0 5px;

    font-size: $fs-default;
    letter-spacing: 2px;
    vertical-align: top;

    @include media-max($md) {
        height: auto !important;
    }
}

.grid-cell--bottom {
    vertical-align: bottom;
}



.w-8 {
    width: 8 / 12 * 100%;
}

.w-6 {
    width: 50%;
}

.w-4 {
    width: 4 / 12 * 100%;
}

@include media-max($md) {
    .w-md-12 {
        width: 100%;
    }

    .w-md-6 {
        width: 50%;
    }
}

@include media-max($sm) {
    .w-sm-12 {
        width: 100%;
    }

    .w-sm-6 {
        width: 50%;
    }
}

@include media-max($xs) {
    .w-xs-12 {
        width: 100%;
    }

    .w-xs-6 {
        width: 50%;
    }
}


.h-12 {
    height: 100%;
}

.h-6 {
    height: 50%;
}


.grid-box {
    @extend .bg-transparent;

    height: 100%;
    padding: 20px;
}