/* Generated by Font Squirrel (http://www.fontsquirrel.com) on July 23, 2015 */

@font-face {
    font-family: 'DolceVita';
    src: url('../fonts/dolce_vita_heavy_bold.eot');
    src: url('../fonts/dolce_vita_heavy_bold.eot?#iefix') format('embedded-opentype'),
         url('../fonts/dolce_vita_heavy_bold.woff2') format('woff2'),
         url('../fonts/dolce_vita_heavy_bold.woff') format('woff'),
         url('../fonts/dolce_vita_heavy_bold.ttf') format('truetype'),
         url('../fonts/dolce_vita_heavy_bold.svg#dolce_vita_heavyafewXpounds') format('svg');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'DolceVita';
    src: url('../fonts/dolce_vita_light.eot');
    src: url('../fonts/dolce_vita_light.eot?#iefix') format('embedded-opentype'),
         url('../fonts/dolce_vita_light.woff2') format('woff2'),
         url('../fonts/dolce_vita_light.woff') format('woff'),
         url('../fonts/dolce_vita_light.ttf') format('truetype'),
         url('../fonts/dolce_vita_light.svg#dolce_vita_lightsuper_light') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'DolceVita';
    src: url('../fonts/dolce_vita.eot');
    src: url('../fonts/dolce_vita.eot?#iefix') format('embedded-opentype'),
         url('../fonts/dolce_vita.woff2') format('woff2'),
         url('../fonts/dolce_vita.woff') format('woff'),
         url('../fonts/dolce_vita.ttf') format('truetype'),
         url('../fonts/dolce_vita.svg#dolce_vitaquite_regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

body, html {
    font-family: 'DolceVita', Helvetica Neue, Helvetica, Arial, sans-serif;
    letter-spacing: 2px;

    text-rendering: optimizeSpeed;
}

h1, .h1 {
    font-size: 44px;
    font-weight: 300;
}

h2, .h2 {
    font-size: 30px;
}

h3, .h3 {
    font-size: 20px;
}

h4, .h4 {
    font-size: $fs-default;
}

.kerning {
    // margin-left: -0.25em;
}